import React from "react";
import { TanyaSearchBlock } from "components/tanya-search-block/tanya-search-block";
import { FAQBlock } from "components/faq-block/faq-block";
import checkIcon from "public/assets/check_circle.png";
import crossIcon from "public/assets/close_circle.png";
import { CompareCard } from "components/comparison/comparison-block";
import {
  ComparisonBlockContainer,
  ComparisonBlockCTA,
  ComparisonWholeContainer
} from "components/comparison/comparison-block-styles";
import { ReviewSection } from "components/review-section/review-section";
import { HomeSEO } from "seo/home.seo";
import { HomeMain } from "styles/pages/home.styles";
import { HeroLeadGenerationSection } from "components/lead-generation-v2/hero-lead-generation-section/hero-lead-generation-section";
import { MoneyBackGuaranteeAppDownload } from "components/money-back-guarantee-app-download/money-back-guarantee-app-download";
import { WhatIsAvailableSection } from "components/what-is-available/what-is-available-section";
import { testimonials } from "data/testimonials";
import { useBoolean } from "hooks/use-boolean/use-boolean";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTanyaContext } from "stores/tanya/tanya-context";
import { sizes } from "styles";
import { getLeadSourceFromPath } from "utils/utils";
import { BimbelLoggedInBanner } from "components/kelas-live/bimbel-logged-in-banner/bimbel-logged-in-banner";
import { useAuth } from "stores/auth/auth-context";
import { MoneyBackBanner } from "components/moneyback-banner/moneyback-banner";

const LeadGenerationModal = dynamic(
  () =>
    import(
      "components/lead-generation-v2/lead-generation-modal/lead-generation-modal"
    )
);

const ContactMe = dynamic(() => import("components/contact-me/contact-me"));

const Home: React.FC = () => {
  const [showLeadGenerationModal, setShowLeadGenerationModal] =
    useBoolean(false);
  const {
    user: { isUserLoggedIn, userData }
  } = useAuth();

  const { contactMeForm } = useTanyaContext();
  const router = useRouter();
  const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } =
    router.query;
  const leadSource = getLeadSourceFromPath(router.pathname);
  return (
    <HomeMain>
      <HomeSEO />
      {isUserLoggedIn && (
        <BimbelLoggedInBanner name={userData.primary_user.full_name} />
      )}
      {!isUserLoggedIn && (
        <HeroLeadGenerationSection // No conditional rendering
          onPriceInfoCTAClick={() => setShowLeadGenerationModal.on()}
          source="Home"
        />
      )}
      <ComparisonWholeContainer>
        <ComparisonBlockContainer>
          <CompareCard
            title="Ada di CoLearn:"
            bullets={[
              "Suasana memotivasi",
              "Guru dengan penalaran Matematika yang kuat",
              "Anak jadi percaya diri karena paham"
            ]}
            bulletImage={checkIcon}
            shadow="#00927429"
          />
          <CompareCard
            title="Tidak ada di CoLearn:"
            bullets={[
              "Hasil belajar instan",
              "Kelas privat",
              "Menanyakan PR di kelas"
            ]}
            bulletImage={crossIcon}
            tanyaLink={"Bantuan PR melalui fitur Tanya klik di sini"}
            shadow="#E8641F29"
          />
        </ComparisonBlockContainer>
        <ComparisonBlockCTA rel="nofollow" href="/bimbel">
          Cara kami mewujudkannya, klik!
        </ComparisonBlockCTA>
      </ComparisonWholeContainer>
      <ReviewSection
        testimonials={testimonials}
        title={<>10.000+ murid tersebar di Indonesia</>}
        width={sizes.homePageTestimonialWidth}
      />
      <WhatIsAvailableSection />
      <MoneyBackGuaranteeAppDownload />
      <MoneyBackBanner />
      <FAQBlock showExpandCTA />
      <TanyaSearchBlock bgTheme="light" showFloatingButton />
      {contactMeForm.show && (
        <ContactMe
          metaData={contactMeForm.metaData}
          onClose={() => contactMeForm.setContactMeFormData({ show: false })}
          leadSource={leadSource}
        />
      )}
      {showLeadGenerationModal && (
        <LeadGenerationModal
          onClose={() => setShowLeadGenerationModal.off()}
          onProceed={() => {
            setShowLeadGenerationModal.off();
            contactMeForm.setContactMeFormData({
              show: true,
              metaData: {
                utm_term,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content
              }
            });
          }}
        />
      )}
    </HomeMain>
  );
};

export default Home;
