import { styled } from "styled-components";
import { Container } from "styles/common/container";

export const BimbelLoggedInWrapper = styled.section`
  height: 360px;
  text-align: center;
  background: ${({ theme }) => theme.colors.yellow[400]};
  background: ${({ theme }) =>
    `radial-gradient(circle, ${theme.colors.yellow[200]} 0%, ${theme.colors.yellow[400]} 50%)`};
  padding: 50px 0 0 0;
  position: relative;

  &:before {
    content: "";
    background: url("/assets/kelas-live/stars-yellow-left.svg") no-repeat;
    display: block;
    width: 150px;
    height: 100%;
    position: absolute;
    left: 8px;
    bottom: 6px;
  }
  &:after {
    content: "";
    background: url("/assets/kelas-live/stars-yellow-right.svg") no-repeat;
    display: block;
    width: 200px;
    height: 100%;
    position: absolute;
    right: 8px;
    bottom: 6px;
  }
`;

export const BimbelLoggedInContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BimbelLoggedInName = styled.p`
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[1000]};
  margin: 22px 0 0 0;
  word-wrap: break-word;
`;

export const BimbelLoggedInHeading = styled.h2`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["4xl"]};
  margin: 32px 0;
`;

export const BimbelLoggedInImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 90px;
`;
