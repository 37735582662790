import { useState, ChangeEvent, useRef, useEffect, useCallback } from "react";
import { useBoolean } from "hooks/use-boolean/use-boolean";
import { IUseTanyaSearchBlock } from "./tanya-search-block.types";
import { constants } from "constants/constants";

const useTanyaSearchBlock: () => IUseTanyaSearchBlock = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showTanyaFloatingButton, setShowTanyaFloatingButton] = useBoolean();
  const tanyaSearchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleFloatingButton);

      return () => {
        window.removeEventListener("scroll", handleFloatingButton);
      };
    }
  }, []);

  const handleFloatingButton = useCallback((e: Event) => {
    if (e.currentTarget) {
      const window = e?.currentTarget as Window;
      if (window.scrollY > constants.FLOATING_BUTTON_SCROLL_TOP) {
        setShowTanyaFloatingButton.on();
      } else {
        setShowTanyaFloatingButton.off();
      }
    }
  }, []);

  const handleFloatingButtonClick = () => {
    if (tanyaSearchInputRef.current) {
      tanyaSearchInputRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
      tanyaSearchInputRef.current.focus();
    }
  };

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmitQuery = () => {
    window.open(
      `https://google.com/search?q=%22colearn.id%22%20+${encodeURIComponent(searchQuery)}`,
      "_blank"
    );
  };

  return {
    searchQuery,
    tanyaSearchInputRef,
    showTanyaFloatingButton,
    handleSearchQuery,
    handleSubmitQuery,
    handleFloatingButtonClick
  };
};

export { useTanyaSearchBlock };
