import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakpoints.mobile} {
    padding: 0 20px 30px 20px;
  }
`;

export const MoneyBackGuaranteeWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const MoneyBackBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 1200px;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.yellow["900"]};
  @media ${breakpoints.mobile} {
    width: 100%;
    gap: 8px;
    padding: 20px;
    border-radius: 8px;
  }
`;

export const MoneyBackImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoneyBackTextWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  line-height: ${({ theme }) => theme.fontSizes["3xl"]};
  color: ${({ theme }) => theme.colors.neutral[1000]};
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["xs"]};
    line-height: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brand[500]};
  font-weight: 700;
`;
