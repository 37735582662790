import React from "react";
import ColearnAppAndSubjectsImg from "public/assets/home/CoLearn-aplikasi-belajar-Matematika-Fisika-Kimia.jpg";
import { CheckIGButton } from "components/download-app-ctas/download-app-ctas.styles";
import {
  Description,
  DownloadCTAContainer,
  Heading,
  ImageWrapper,
  MoneyBackGuaranteeContainer,
  Wrapper,
  ImageContainer1
} from "./money-back-guarantee-app-download.styles";
import InstagramIcon from "public/assets/instaWhite.png";
import { DownloadAppCTAs } from "components/download-app-ctas/download-app-ctas";
import Image from "next/image";

export const MoneyBackGuaranteeAppDownload: React.FC = () => {
  const link = "https://www.instagram.com/colearn.id/";
  return (
    <Wrapper>
      <MoneyBackGuaranteeContainer>
        <div>
          <Heading>Bergabunglah sekarang!</Heading>
          <Description>95.000/bulan, pembayaran bulanan.</Description>
          <DownloadCTAContainer>
            <DownloadAppCTAs />
            <CheckIGButton rel="nofollow" href={link}>
              Info lengkap? Cek IG CoLearn
              <Image
                src={InstagramIcon}
                alt="Whatsapp Icon"
                layout="fixed"
                width={18}
                height={18}
                style={{ marginBottom: "2px" }}
              />
            </CheckIGButton>
          </DownloadCTAContainer>
        </div>
        <ImageWrapper>
          <ImageContainer1
            src={ColearnAppAndSubjectsImg}
            alt="CoLearn aplikasi belajar Matematika Fisika Kimia"
            layout="responsive"
          />
        </ImageWrapper>
      </MoneyBackGuaranteeContainer>
    </Wrapper>
  );
};
