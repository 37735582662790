import React from "react";
import { IComparisonBlockProps } from "./comparison-block-types";
import {
  ComparisonBlockContent,
  ComaparisonBlockTitle,
  ComparisonBlockBullet,
  TanyaLink,
  ImageContainer
} from "./comparison-block-styles";

const CompareCard: React.FC<IComparisonBlockProps> = ({
  title,
  bullets,
  bulletImage,
  tanyaLink,
  shadow
}) => {
  return (
    <ComparisonBlockContent shadow={shadow}>
      <ComaparisonBlockTitle>{title}</ComaparisonBlockTitle>
      <ComparisonBlockBullet>
        <ul>
          {bullets.map((bullet) => (
            <li key={`${title}-${bullet}`}>
              <ImageContainer src={bulletImage} alt="bullet" />
              {bullet}
            </li>
          ))}
        </ul>
        {tanyaLink && (
          <TanyaLink rel="nofollow" href="/tanya">
            {tanyaLink}
          </TanyaLink>
        )}
      </ComparisonBlockBullet>
    </ComparisonBlockContent>
  );
};

export { CompareCard };
