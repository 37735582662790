import styled from "styled-components";

export const PremiumBadgeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.brand[900]};
  color: ${({ theme }) => theme.colors.yellow[500]};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 1;
  border-radius: 0 120px 120px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
  max-width: 100px;
  position: relative;
  padding: 0 12px 0 20px;
`;

export const PremiumBadgeIcon = styled.span`
  position: absolute;
  left: -17px;
  top: 50%;
  transform: translateY(-45%);
`;
