import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";
import Image from "next/image";

export const ComparisonWholeContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 10px 0 70px 0;
  @media ${breakpoints.mobile} {
    padding: 40px 8px;
  }
`;

export const ComparisonBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px auto;
  max-width: 1200px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 0 8px;
    max-width: 400px;
    margin: 0;
    margin-bottom: 30px;
    gap: 16px;
  }
`;

export const TanyaLink = styled.a`
  color: ${({ theme }) => theme.colors.brand[500]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  margin-left: 40px;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["xs"]};
  }
`;

export const ComparisonBlockContent = styled.div<{ shadow: string }>`
  flex: 1;
  background-color: #fff;
  border: 0px solid #ddd;
  border-radius: 16px;
  padding: 24px 26px 18px 24px;
  gap: 8px;
  box-shadow: 0px 0px 68px 0px ${({ shadow }) => shadow};
  margin: 8px;
  max-width: 1200px;
  min-width: 450px;
  @media ${breakpoints.mobile} {
    padding: 24px 24px 15px 24px;
    margin: 0;
    min-width: 300px;
    max-width: 300px;
  }
`;

export const ComaparisonBlockTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 28px;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  text-align: left;
  margin: 0 0 16px;
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
    line-height: 42px;
    width: fit-content;
  }
`;

export const ComparisonBlockBullet = styled.div`
  width: 400px;
  ul {
    list-style-type: none;
    padding: 0;
    width: 320px;
  }
  li {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.neutral[1000]};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
    line-height: 16px;
    white-space: nowrap;
    @media ${breakpoints.mobile} {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      white-space: wrap;
      line-height: 24px;
    }
  }

  span {
    margin-right: 8px;
    font-weight: bold;
  }
`;

export const ComparisonBlockCTA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
  font-weight: bold;
  padding: 12px 24px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.brand[100]};
  color: ${({ theme }) => theme.colors.brand[500]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand[200]};
  }

  @media ${breakpoints.tablet} {
    display: inline-flex;
    justify-content: flex-start;
  }
`;
export const ImageContainer = styled(Image)`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;
