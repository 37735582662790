import {
  PremiumBadgeContainer,
  PremiumBadgeIcon
} from "./premium-badge.styles";

const PremiumBadge = () => {
  return (
    <PremiumBadgeContainer>
      <PremiumBadgeIcon>
        <svg
          width="32"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0384 1.49038C18.098 -0.496796 14.902 -0.496795 12.9616 1.49039L11.7193 2.76266C11.4044 3.08522 10.971 3.26471 10.5202 3.25934L8.74215 3.23816C5.96493 3.20508 3.70508 5.46493 3.73816 8.24215L3.75934 10.0202C3.76471 10.471 3.58522 10.9044 3.26266 11.2193L1.99038 12.4616C0.00320467 14.402 0.00320572 17.598 1.99038 19.5384L3.26266 20.7807C3.58522 21.0956 3.76471 21.529 3.75934 21.9798L3.73816 23.7578C3.70508 26.5351 5.96493 28.7949 8.74215 28.7618L10.5202 28.7407C10.971 28.7353 11.4044 28.9148 11.7193 29.2373L12.9616 30.5096C14.902 32.4968 18.098 32.4968 20.0384 30.5096L21.2807 29.2373C21.5956 28.9148 22.029 28.7353 22.4798 28.7407L24.2579 28.7618C27.0351 28.7949 29.2949 26.5351 29.2618 23.7579L29.2407 21.9798C29.2353 21.529 29.4148 21.0956 29.7373 20.7807L31.0096 19.5384C32.9968 17.598 32.9968 14.402 31.0096 12.4616L29.7373 11.2193C29.4148 10.9044 29.2353 10.471 29.2407 10.0202L29.2618 8.24215C29.2949 5.46493 27.0351 3.20508 24.2579 3.23816L22.4798 3.25934C22.029 3.26471 21.5956 3.08522 21.2807 2.76266L20.0384 1.49038Z"
            fill="#FFD12E"
          />
          <path
            d="M15.5423 6.25598C16.1393 5.91467 16.8607 5.91467 17.4577 6.25598L24.4577 10.2583C25.1002 10.6256 25.5 11.3315 25.5 12.0987V19.9013C25.5 20.6685 25.1002 21.3744 24.4577 21.7417L17.4577 25.744C16.8607 26.0853 16.1393 26.0853 15.5423 25.744L8.54229 21.7417C7.89977 21.3744 7.5 20.6685 7.5 19.9013V12.0987C7.5 11.3315 7.89977 10.6256 8.5423 10.2583L15.5423 6.25598Z"
            fill="#E8641F"
          />
          <path
            d="M22.0257 15.1754L19.7161 16.9858C19.6484 17.0391 19.5979 17.11 19.5708 17.1899C19.5436 17.2699 19.5409 17.3556 19.5629 17.437L20.258 20.1709C20.2855 20.2664 20.2899 20.3667 20.2708 20.4642C20.2518 20.5616 20.2098 20.6535 20.148 20.733C20.0862 20.8125 20.0063 20.8774 19.9144 20.9227C19.8225 20.9681 19.721 20.9927 19.6176 20.9947C19.489 20.995 19.3632 20.9585 19.2564 20.8898L16.7334 19.4048C16.6593 19.3612 16.574 19.3381 16.4871 19.3381C16.4001 19.3381 16.3149 19.3612 16.2408 19.4048L13.7506 20.895C13.6437 20.9637 13.518 21.0003 13.3893 21C13.2861 21.0007 13.1841 20.9783 13.0915 20.9344C12.9989 20.8906 12.9182 20.8266 12.856 20.7476C12.7938 20.6686 12.7517 20.5767 12.7331 20.4793C12.7145 20.3819 12.72 20.2817 12.749 20.1866L13.4441 17.4528C13.4654 17.3713 13.4624 17.2858 13.4353 17.206C13.4081 17.1261 13.3581 17.0553 13.2908 17.0015L11.0141 15.1964C10.9102 15.1118 10.835 14.9993 10.7983 14.8734C10.7615 14.7476 10.7648 14.6141 10.8078 14.49C10.8507 14.366 10.9313 14.257 11.0392 14.1772C11.1472 14.0974 11.2775 14.0503 11.4136 14.042L14.3635 13.8373C14.4494 13.8323 14.5321 13.804 14.6018 13.7557C14.6716 13.7074 14.7256 13.6411 14.7576 13.5645L15.885 10.9408C15.931 10.8138 16.0166 10.7034 16.1301 10.6246C16.2437 10.5457 16.3797 10.5023 16.5199 10.5C16.6564 10.4991 16.79 10.5377 16.9032 10.6107C17.0165 10.6837 17.1041 10.7878 17.1548 10.9093L18.2822 13.533C18.315 13.6095 18.3691 13.6759 18.4386 13.725C18.5081 13.774 18.5903 13.8038 18.6763 13.8111L21.6262 14.0157C21.7635 14.023 21.8954 14.0697 22.0046 14.1498C22.1138 14.2299 22.1953 14.3398 22.2384 14.465C22.2816 14.5902 22.2843 14.7249 22.2464 14.8516C22.2085 14.9784 22.1316 15.0912 22.0257 15.1754Z"
            fill="#FFD12E"
          />
        </svg>
      </PremiumBadgeIcon>
      <span> Premium</span>
    </PremiumBadgeContainer>
  );
};

export { PremiumBadge };
