import {
  MoneyBackBannerContainer,
  MoneyBackImgWrapper,
  MoneyBackTextWrapper,
  Wrapper,
  MoneyBackGuaranteeWrapper,
  StyledLink
} from "./moneyback-banner.styles";
import GaransiLogo from "public/assets/garansi-logo.svg";
import Image from "next/image";

export const MoneyBackBanner: React.FC = () => {
  return (
    <Wrapper>
      <MoneyBackGuaranteeWrapper>
        <MoneyBackBannerContainer>
          <MoneyBackImgWrapper>
            <Image
              src={GaransiLogo}
              alt="Garansi 100% uang kembali"
              layout="responsive"
              sizes="(min-width: 768px) 33vw, 80vw"
              priority
            />
          </MoneyBackImgWrapper>
          <MoneyBackTextWrapper>
            Garansi uang kembali 100% di bulan pertama. Info selengkapnya:{" "}
            <StyledLink href="/garansi-uang-kembali">klik di sini</StyledLink>
          </MoneyBackTextWrapper>
        </MoneyBackBannerContainer>
      </MoneyBackGuaranteeWrapper>
    </Wrapper>
  );
};
