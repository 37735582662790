import { Button } from "components/atoms/button/button";
import { PremiumBadge } from "../premium-badge/premium-badge";
import {
  BimbelLoggedInContainer,
  BimbelLoggedInHeading,
  BimbelLoggedInImageContainer,
  BimbelLoggedInName,
  BimbelLoggedInWrapper
} from "./bimbel-logged-in-banner.styles";
import { Arrow } from "components/icons/arrow";
import Image from "next/image";
import { useRouter } from "next/router";

const BimbelLoggedInBanner = ({ name }: { name: string }) => {
  const router = useRouter();
  const handleClick = () => router.push("/bimbel/user");
  return (
    <BimbelLoggedInWrapper>
      <BimbelLoggedInContainer>
        <PremiumBadge />
        <BimbelLoggedInName>{name}</BimbelLoggedInName>
        <BimbelLoggedInHeading>
          Cek kelas Bimbel mu hari ini yuk!
        </BimbelLoggedInHeading>
        <Button variant="primary" onClick={handleClick}>
          Cek kelas Bimbel &nbsp; <Arrow />
        </Button>
        <BimbelLoggedInImageContainer>
          <Image
            src="/assets/home/coco_and_neco.svg"
            alt="coco and neco"
            fill
          />
        </BimbelLoggedInImageContainer>
      </BimbelLoggedInContainer>
    </BimbelLoggedInWrapper>
  );
};

export { BimbelLoggedInBanner };
