import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const Wrapper = styled.section`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const WhatIsAvailableContainer = styled(Container)`
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${breakpoints.laptop} {
    padding: ${rem(80)};
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 30px 0 0 0;
  }
`;

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  margin: 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
    margin: 0 0 40px;
  }
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 15px;
    padding: 20px 0;
  }
`;

export const Card = styled.div`
  position: relative;
  min-height: 180px;
  min-width: 280px;
  @media ${breakpoints.tablet} {
    min-width: 380px;
  }
  @media ${breakpoints.mobile} {
    min-width: 410px;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 25px;
  padding: 0 12px;
  @media ${breakpoints.tablet} {
    left: 45px;
  }
  @media ${breakpoints.mobile} {
    left: 30px;
    bottom: 30px;
  }
`;

export const Grade = styled.div<{ grade: string }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: ${({ theme, grade }) =>
    grade === "Matematika" ? theme.fontSizes["2xl"] : theme.fontSizes["lg"]};

  @media ${breakpoints.mobile} {
    padding-left: 20px;
  }
`;

export const GradeSub = styled.div<{ gradeSub: string }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: ${({ theme, gradeSub }) =>
    gradeSub === "4 SD - 12 SMA"
      ? theme.fontSizes["2xl"]
      : theme.fontSizes["lg"]};
  @media ${breakpoints.mobile} {
    padding-left: 20px;
  }
`;
